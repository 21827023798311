import { BatchTransfer } from "@/interfaces/batchTransferManager";
import { Location } from "@/interfaces/location";
import { i18n } from "@/plugins/i18n";
import {
  addTimeZeros,
  FNS_DATE_FORMATS,
  fnsFormatDate,
  fnsParse,
  fnsUtcToZonedTime
} from "@/utils/date-fns.utils";
import { addHours, addMinutes, addSeconds } from "date-fns";
import { ManifestModel } from "./Manifest/Manifest.component";

export const parseAddress = (
  address: string,
  city?: string,
  state?: string,
  zip?: string
) => {
  if (!address) {
    return String(
      i18n.t("batch_transfer_manager_module.messages.address_not_defined")
    );
  }
  return `${address} ${city ? city + "," : ""} ${state ? state : ""} ${
    zip ? zip : ""
  }`;
};

export const calculateEstimatedTime = (manifestModel: ManifestModel) => {
  if (manifestModel.start_date && manifestModel.start_time) {
    const deliveryStartup = fnsParse(
      `${manifestModel.start_date} ${manifestModel.start_time}`,
      FNS_DATE_FORMATS.EN_BARS_WITH_MERIDIEM_TIME
    );

    manifestModel.destinations!.forEach(
      (
        destination: BatchTransfer.Destination,
        index: number,
        destinations: BatchTransfer.Destination[]
      ) => {
        const previousStop = destinations[index - 1];
        const currentStop = manifestModel.legs[index];

        if (!currentStop) {
          return;
        }

        const stopStartTime = !!previousStop
          ? addDuration(
              previousStop.estimated_arrival!,
              manifestModel.drop_off_time
            )
          : deliveryStartup;

        const stopTravelTimeDuration = new Date(
          0,
          0,
          0,
          0,
          0,
          currentStop.duration.value
        )
          .toString()
          .split(" ")[4];

        destination.estimated_travel_time = addTimeZeros(
          `${stopTravelTimeDuration}`
        );

        let addition = addDuration(stopStartTime, `${stopTravelTimeDuration}`);

        addition = addDuration(addition, manifestModel.drop_off_time);

        destination.estimated_arrival = fnsFormatDate(
          addition,
          FNS_DATE_FORMATS.EN_DASH_WITH_MERIDIEM_TIME
        );
      }
    );

    const lastStop = manifestModel.destinations![
      manifestModel.destinations!.length - 1
    ];

    const deliveryEndTime = lastStop.estimated_arrival
      ? parseLastStopArrival(
          lastStop.estimated_arrival,
          FNS_DATE_FORMATS.EN_BARS_WITH_MERIDIEM_TIME
        )
      : null;

    if (deliveryEndTime) {
      manifestModel.end_date = deliveryEndTime.split(" ")[0];
      manifestModel.end_time = `${deliveryEndTime.split(" ")[1]} ${
        deliveryEndTime.split(" ")[2]
      }`;
    }
  }
};

export const addDuration = (
  initialDate: string | Date,
  duration: string
): Date => {
  const split = duration.split(":");
  let date =
    typeof initialDate === "string"
      ? fnsParse(initialDate, FNS_DATE_FORMATS.EN_DASH_WITH_MERIDIEM_TIME)
      : initialDate;
  date = addHours(date, +split[0]);
  date = addMinutes(date, +split[1]);
  date = addSeconds(date, +split[2]);

  return date;
};

export const calcRoute = (
  currentLocation: Location,
  destinations: BatchTransfer.Destination[]
): Promise<google.maps.DirectionsResult> => {
  return new Promise((resolve, reject) => {
    const destinationsCopy: BatchTransfer.Destination[] = [...destinations];

    const lastItem = destinationsCopy.pop();
    const waypoints = destinationsCopy.map(
      (transfer: BatchTransfer.Destination) => {
        return {
          location: parseAddress(transfer.address),
          stopover: true
        };
      }
    );

    const directionService = new google.maps.DirectionsService();
    const currentLocationAddress = parseAddress(
      currentLocation.address1 || currentLocation.address2,
      currentLocation.city,
      `${currentLocation.state}`,
      currentLocation.zip
    );

    const request: google.maps.DirectionsRequest = {
      origin: currentLocationAddress,
      destination: parseAddress(lastItem!.address),
      waypoints,
      travelMode: google.maps.TravelMode.DRIVING,
      unitSystem: google.maps.UnitSystem.IMPERIAL
    };

    directionService.route(
      request,
      async (
        result: google.maps.DirectionsResult,
        status: google.maps.DirectionsStatus
      ) => {
        if (status === google.maps.DirectionsStatus.OK) {
          resolve(result);
        } else {
          reject();
        }
      }
    );
  });
};

export const htmDestinationRow = (
  step: google.maps.DirectionsStep
): HTMLDivElement => {
  const stylePrefix = "google-route__";
  // APPEND ROW
  const row = document.createElement("div");
  row.classList.add(stylePrefix + "row");
  // APPEND TIME
  const timeAndDistance = document.createElement("span");
  timeAndDistance.classList.add(stylePrefix + "time-and-distance");
  timeAndDistance.innerHTML = step.duration.text + " " + step.distance.text;
  row.append(timeAndDistance);
  // APPEND DIVIDER
  const divider = document.createElement("span");
  divider.innerText = "|";
  divider.classList.add(stylePrefix + "divider");
  row.append(divider);
  // APPEND INSTRUCTION
  const instruction = document.createElement("span");
  instruction.classList.add(stylePrefix + "instruction");
  instruction.innerHTML = step.instructions;
  row.append(instruction);
  const innerRow = instruction.querySelector("div");
  if (innerRow) {
    innerRow!.classList.add(stylePrefix + "extra");
    row.append(innerRow);
  }
  return row;
};

export const buildDestinationRoute = (
  destination: google.maps.DirectionsLeg,
  appendTo: string = ""
): string => {
  return destination.steps.reduce(
    (acum: string, step: google.maps.DirectionsStep) => {
      const row = htmDestinationRow(step);

      if (appendTo.length > 0) {
        document.querySelector(appendTo)!.append(row);
      }

      return acum + row.innerHTML;
    },
    ""
  );
};

export const parseLastStopArrival = (
  estimatedTime: string | null,
  outputFormat: string
): string | null => {
  if (!estimatedTime) {
    return null;
  }

  const date = estimatedTime.includes("T")
    ? fnsUtcToZonedTime(new Date(estimatedTime))
    : fnsParse(estimatedTime, FNS_DATE_FORMATS.EN_DASH_WITH_MERIDIEM_TIME);

  return fnsFormatDate(date, outputFormat);
};
