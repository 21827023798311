import { BatchTransfer } from "@/interfaces/batchTransferManager";
import VueBarcode from "vue-barcode";
import { Component, Prop, Vue } from "vue-property-decorator";
import MainPageComponent from "./mainPage/mainPage.component";
import Template from "./manifestPrint.template.vue";
import TransferPageComponent from "./transferPage/transferPage.component";

@Component({
  mixins: [Template],
  components: {
    TransferPageComponent,
    MainPageComponent,
    VueBarcode
  }
})
export default class ManifestPrint extends Vue {
  @Prop({ required: true })
  public manifest!: BatchTransfer.Manifest;
  public data: BatchTransfer.Manifest = {
    ...this.manifest,
    destinations: this.manifest.destinations!.sort((a, b) => a.order - b.order)
  };
  public get totalPages() {
    return this.manifest.destinations!.length + 1;
  }
  protected mounted() {
    this.$emit("readyToPrint");
  }
  public get barcodeStyles() {
    return {
      height: 24,
      lineColor: "#525252"
    };
  }
}
