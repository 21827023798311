import { BatchTransfer } from "@/interfaces/batchTransferManager";
import { Component, Prop, Vue, Watch } from "vue-property-decorator";
// @ts-ignore
import draggable from "vuedraggable";
import { ManifestModel } from "../Manifest.component";
import Template from "./OutboundTransferDestination.template.vue";

@Component({
  mixins: [Template],
  components: {
    draggable
  }
})
export default class OutboundTransferDestination extends Vue {
  public transfersCopy: BatchTransfer.Destination[] = [];
  @Prop({ required: true }) public manifestModel!: ManifestModel;
  @Prop() public isLoading!: boolean;
  @Prop() public startingPoint!: string;

  public changedArray(newPriorityArray: BatchTransfer.Destination[]) {
    this.$emit(
      "updatedPriorityArray",
      newPriorityArray.map((destination, index) => {
        return {
          ...destination,
          order: index
        };
      })
    );
  }

  @Watch("manifestModel.destinations")
  public updateDestinations() {
    this.transfersCopy = [...this.manifestModel.destinations];
  }

  public mounted() {
    if (this.manifestModel.destinations) {
      this.transfersCopy = [...this.manifestModel.destinations];
    }
  }
}
