import { ManifestMapper } from "@/components/inventory/BatchTransfer/EntityMappers/Manifest.mapper";
import { BatchTransfer } from "@/interfaces/batchTransferManager";
import { i18n } from "@/plugins/i18n";
import { SecurityPinService } from "@/plugins/security-pin/security-pin.service";
import HttpService from "@/services/http.service";
import { AxiosResponse } from "axios";
import { TablePagination } from "helix-vue-components";
import { Vue } from "vue-property-decorator";
import { messagesService } from "../messages.service";

export class ManifestService extends HttpService {
  protected mapper: ManifestMapper = new ManifestMapper();
  public async getAll(params?: { [key: string]: any }) {
    const response: AxiosResponse<any> = await Vue.axios({
      method: "GET",
      url: `${process.env.VUE_APP_BASE_URL}/inventory/transfers/manifests`,
      params: {
        ...params,
        ...{ embed: "user" }
      }
    });
    const manifests = response.data.data.data.map((manifest: any) =>
      this.mapper.mapInput(manifest)
    );
    const pagination: TablePagination = {
      itemsPerPage: response.data.data.per_page,
      totalItems: response.data.data.total,
      currentPage: response.data.data.current_page,
      itemsPerPageOptions: [5, 10, 20, 50],
      from: response.data.data.from,
      to: response.data.data.to
    };
    return { data: manifests, pagination };
  }
  public async getById(id: number, params?: { [key: string]: any }) {
    const response: AxiosResponse<any> = await Vue.axios({
      method: "GET",
      url: `${process.env.VUE_APP_BASE_URL}/inventory/transfers/manifests/${id}`
    });
    return this.mapper.mapInput(response.data.data);
  }
  public async create(manifest: BatchTransfer.Manifest) {
    try {
      const response: AxiosResponse<any> = await Vue.axios({
        method: "POST",
        url: `${process.env.VUE_APP_BASE_URL}/inventory/transfers/manifests`,
        data: this.mapper.mapOutput(manifest)
      });

      return this.mapper.mapInput(response.data.data);
    } catch (e) {
      messagesService.renderErrorMessage(e);
      return;
    }
  }
  public async update(manifest: BatchTransfer.Manifest) {
    try {
      const response: AxiosResponse<any> = await Vue.axios({
        method: "PUT",
        url: `${process.env.VUE_APP_BASE_URL}/inventory/transfers/manifests/${
          manifest.id
        }`,
        data: this.mapper.mapOutput(manifest)
      });

      return this.mapper.mapInput(response.data.data);
    } catch (e) {
      messagesService.renderErrorMessage(e);
      return;
    }
  }
  public async void(id: number, params?: { [key: string]: any }) {
    const pin$ = new SecurityPinService();
    try {
      const pin = await pin$.ensure(
        "Security Pin",
        "You must enter a valid PIN code to void this sale"
      );
      try {
        const response: AxiosResponse<any> = await Vue.axios({
          method: "POST",
          url: `${
            process.env.VUE_APP_BASE_URL
          }/inventory/transfers/manifests/${id}/void`,
          params: { ...params },
          headers: {
            Pincode: pin
          }
        });
        return response;
      } catch (e) {
        messagesService.renderErrorMessage(e);
        return;
      }
    } catch (e) {
      messagesService.showMessage(
        "fas fa-exclamation-circle",
        String(i18n.t("security_pin.required_message")),
        "error"
      );
    }
  }
  public async complete(id: number, params?: { [key: string]: any }) {
    const response: AxiosResponse<any> = await Vue.axios({
      method: "POST",
      url: `${
        process.env.VUE_APP_BASE_URL
      }/inventory/transfers/manifests/${id}/complete`,
      params: { ...params }
    });
    return response;
  }
}
export default new ManifestService();
