import { fnsBasicDate } from "@/filters/date-fns.filter";
import {
  BatchTransfer,
  TRANSFER_STATUS
} from "@/interfaces/batchTransferManager";
import reduce from "lodash/reduce";
import { Component, Prop, Vue } from "vue-property-decorator";
import Template from "./mainPage.template.vue";

@Component({
  mixins: [Template],
  filters: {
    fnsBasicDate
  }
})
export default class MainPageComponent extends Vue {
  @Prop({ required: true }) public manifest!: BatchTransfer.Manifest;

  public get numberOfBatches() {
    return reduce(
      this.manifest.destinations,
      (agg, destination) => {
        return agg + destination.transfer!.items.length;
      },
      0
    );
  }

  public isDestinationVoided(destination: BatchTransfer.Destination): boolean {
    return destination.transfer!.status === TRANSFER_STATUS.CANCELED;
  }
}
