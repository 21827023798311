import { BatchTransfer } from "@/interfaces/batchTransferManager";
import { Driver } from "@/interfaces/driver";
import { Vehicle } from "@/interfaces/vehicle";
import {
  addTimeZeros,
  fnsFormatDate,
  fnsParse,
  fnsUtcToZonedTime
} from "@/utils/date-fns.utils";
import addHours from "date-fns/addHours";
import addMinutes from "date-fns/addMinutes";
import addSeconds from "date-fns/addSeconds";
import differenceInDays from "date-fns/differenceInDays";
import isAfter from "date-fns/isAfter";
// tslint:disable-next-line
import {} from "googlemaps";
import {
  HelixDatePickerComponent,
  HelixDatePickerOptions
} from "helix-vue-components";
import { Component, Prop, Vue, Watch } from "vue-property-decorator";
import { calculateEstimatedTime } from "../../routing.utils";
import { ManifestModel, TimeUnitOfMesure } from "../Manifest.component";
import { FNS_DATE_FORMATS } from "./../../../../../utils/date-fns.utils";
import Template from "./ManifestForm.template.vue";
import NewDriver from "./NewDriver/NewDriver.component";
import NewVehicle from "./NewVehicle/NewVehicle.component";

@Component({
  mixins: [Template],
  components: {
    HelixDatePickerComponent
  }
})
export default class ManifestForm extends Vue {
  public time: string = "";
  public openedPickers: { [key: string]: boolean } = {
    start_date: false,
    start_time: false,
    end_date: false,
    end_time: false
  };
  @Prop({ required: true }) public manifestModel!: ManifestModel;
  @Prop({ required: true }) public vehicles!: Vehicle[];
  @Prop({ required: true }) public drivers!: Driver[];

  public timeUnitsOfMesure: Array<{ name: string; key: string }> = [
    {
      name: "hours",
      key: "h"
    },
    {
      name: "minutes",
      key: "m"
    }
  ];

  public estimatedArrivals: Array<{ key: string; value: string }> = [];

  public created() {
    this.$validator.extend("validateEnd", () => this.validateEnd());
  }
  public openDriverModal() {
    const title = "Add Driver";
    return new Promise(async (resolve, reject) => {
      try {
        const driverModal = await this.$modals.load<{}>(NewDriver, {
          title,
          size: "fit",
          positionY: "top"
        });
        resolve(driverModal);
      } catch (e) {
        this.$emit("onAddNewDriver");
      }
    });
  }

  public openVehiclesModal() {
    const title = "Add Vehicle";
    return new Promise(async (resolve, reject) => {
      try {
        const vehicleModal = await this.$modals.load<{}>(NewVehicle, {
          title,
          size: "fit",
          positionY: "top"
        });
        resolve(vehicleModal);
      } catch (e) {
        this.$emit("onAddNewVehicle");
      }
    });
  }

  protected set dropOffTime(amount: number) {
    const dropOffTime =
      this.manifestModel.timeUnitOfMesure === TimeUnitOfMesure.minutes
        ? "00:" + `00${amount}`.slice(-2) + ":00"
        : `00${amount}`.slice(-2) + ":00:00";

    this.manifestModel.drop_off_time = dropOffTime;
  }

  protected get dropOffTime() {
    const dropOffTime = fnsParse(
      this.manifestModel.drop_off_time || "00:00:00",
      FNS_DATE_FORMATS.LOCAL_TIME
    );

    return this.manifestModel.timeUnitOfMesure === TimeUnitOfMesure.minutes
      ? dropOffTime.getMinutes()
      : dropOffTime.getHours();
  }

  public get startTime() {
    return this.manifestModel.start_time
      ? this.manifestModel.start_time.split(" ")[0]
      : "";
  }

  public set startTime(value: string) {
    this.manifestModel.start_time = fnsFormatDate(
      fnsParse(value, "HH:mm"),
      FNS_DATE_FORMATS.LT
    );
  }

  public get endTime() {
    return this.manifestModel.end_time
      ? this.manifestModel.end_time.split(" ")[0]
      : "";
  }

  public set endTime(value: string) {
    this.manifestModel.end_time = fnsFormatDate(
      fnsParse(value, "HH:mm"),
      FNS_DATE_FORMATS.LT
    );
  }

  public get endDateOptions(): Partial<HelixDatePickerOptions> {
    return {
      required: true,
      name: "end_date",
      "value-format": "MM/dd/yyyy",
      disabled: !this.manifestModel.manualRoute,
      "picker-options": {
        disabledDate: (date: Date) =>
          differenceInDays(
            this.manifestModel.start_date
              ? new Date(this.manifestModel.start_date)
              : new Date(),
            date
          ) > 0
      }
    };
  }

  @Watch("manifestModel.legs")
  @Watch("manifestModel.start_time")
  @Watch("manifestModel.drop_off_time")
  public transfersChanged() {
    this.calculateEstimatedTime();
  }

  public validateEnd() {
    const start = fnsParse(
      `${this.manifestModel.start_date} ${this.manifestModel.start_time}`,
      FNS_DATE_FORMATS.EN_BARS_WITH_MERIDIEM_TIME
    );

    const end = fnsParse(
      `${this.manifestModel.end_date} ${this.manifestModel.end_time}`,
      FNS_DATE_FORMATS.EN_BARS_WITH_MERIDIEM_TIME
    );

    return isAfter(end, start);
  }

  public calculateEstimatedTime() {
    if (!this.manifestModel.manualRoute) {
      calculateEstimatedTime(this.manifestModel);
    }
  }
}
