import { Vehicle } from "@/interfaces/vehicle";
import { vehiclesService } from "@/services/vehicles.service";
import { HelixDatePickerComponent } from "helix-vue-components";
import { Component, Vue } from "vue-property-decorator";
import Template from "./NewVehicle.template.vue";

@Component({
  mixins: [Template],
  components: {
    HelixDatePickerComponent
  }
})
export default class NewVehicle extends Vue {
  /**
   * Used to manipulate datepicker programatic closing.
   * (Due to the fact that datepicker is a floating element
   * and is inside another floating element as the modal,
   * we need to manually close it on user date selection)
   */
  public showDatepicker = false;
  public vehiclesForm: Vehicle = {
    name: "",
    color: "",
    make: "",
    vin_number: "",
    model: "",
    license_plate: "",
    year: null,
    expiration: ""
  };

  private isLoading = false;

  public closeModal() {
    this.$emit("reject");
  }

  public get yearList(): string[] {
    const currentYear = Number(new Date().getFullYear());
    const firstYear = 1900;
    const years = [];
    for (let year = currentYear; year >= firstYear; year--) {
      years.push(String(year));
    }
    return years;
  }

  public async addNewVehicle() {
    const validator = await this.$validator.validateAll();
    const dateIsValid = await (this.$refs[
      "hlx-datepicker"
    ] as HelixDatePickerComponent).validate();
    if (validator && dateIsValid) {
      this.isLoading = true;
      await vehiclesService.create(this.vehiclesForm);
      this.closeModal();
    }
  }
}
