import { Driver } from "@/interfaces/driver";
import { driverService } from "@/services/driver.service";
import { HelixDatePickerComponent } from "helix-vue-components";
import { Component, Vue } from "vue-property-decorator";
import Template from "./NewDriver.template.vue";

@Component({
  mixins: [Template],
  components: {
    HelixDatePickerComponent
  }
})
export default class NewDriver extends Vue {
  /**
   * Used to close datepicker programatically.
   */
  public showDatepicker = false;
  public driverForm: Driver = {
    firstname: "",
    license: "",
    license_expiration: "",
    lastname: ""
  };

  private isLoading = false;

  public closeModal() {
    this.$emit("reject");
  }

  public async addNewDriver() {
    const validator = await this.$validator.validateAll();
    const dateIsValid = await (this.$refs[
      "hlx-datepicker"
    ] as HelixDatePickerComponent).validate();
    if (validator && dateIsValid) {
      this.isLoading = true;
      await driverService.create(this.driverForm);
      this.closeModal();
    }
  }
}
