import { EventBus } from "@/event-bus";
import {} from "googlemaps";
import { Component, Prop, Vue, Watch } from "vue-property-decorator";
import { buildDestinationRoute } from "../../routing.utils";
import { ManifestModel } from "../Manifest.component";
import Template from "./Route.template.vue";

@Component({
  mixins: [Template]
})
export default class RouteManifest extends Vue {
  @Prop() public manifestModel!: ManifestModel;
  @Prop() public haveTransfers!: boolean;
  @Prop() public isLoading!: boolean;
  @Prop() public startingPoint!: string;

  @Watch("manifestModel.legs")
  public makeRoute() {
    if (this.manifestModel.manualRoute) {
      return;
    }
    document.querySelector(".google-route")!.innerHTML = "";
    let route = "";
    this.manifestModel.stepsRoutes = [];
    this.manifestModel.legs
      .filter(
        destination => destination.start_address !== destination.end_address
      )
      .forEach(destination => {
        const isNotSameDestination =
          destination.start_address !== destination.end_address;
        const parsedRoute = buildDestinationRoute(destination, ".google-route");
        route += isNotSameDestination ? parsedRoute : "";
        this.manifestModel.stepsRoutes = [
          ...this.manifestModel.stepsRoutes,
          parsedRoute
        ];
      });

    this.manifestModel.route = route;
  }
  public set manualRoute(value: boolean) {
    this.manifestModel.manualRoute = value;
    if (this.manifestModel.manualRoute) {
      this.manifestModel.route = "manual_route";
    } else {
      this.manifestModel.route = "";
      this.makeRoute();
    }
  }

  public get manualRoute() {
    return this.manifestModel.manualRoute;
  }

  public onManualChange() {
    EventBus.$emit("manualRouteChange");
  }
}
