import {
  BatchTransfer,
  ExternalBatchTransfer
} from "@/interfaces/batchTransferManager";
import {
  FNS_DATE_FORMATS,
  fnsFormatDate,
  fnsParse,
  fnsUtcToZonedTime
} from "@/utils/date-fns.utils";
import OutboundTransferMapper from "./OutboundTransfer.mapper";

export class ManifestMapper {
  protected outboundMapper: OutboundTransferMapper = new OutboundTransferMapper();

  public mapInput(
    manifest: ExternalBatchTransfer.Input.ManifestDeclaration.Manifest
  ): BatchTransfer.Manifest {
    const departure = fnsUtcToZonedTime(new Date(manifest.departure));
    const arrival = fnsUtcToZonedTime(new Date(manifest.arrival));
    return {
      id: manifest.id,
      barcode: manifest.barcode,
      location_id: manifest.location_id,
      location: manifest.location,
      vehicle_id: manifest.vehicle_id,
      vehicle: manifest.vehicle,
      driver_id: manifest.driver_id,
      driver: manifest.driver,
      start_date: fnsFormatDate(departure, FNS_DATE_FORMATS.BARS_DEFAULT),
      start_time: fnsFormatDate(departure, FNS_DATE_FORMATS.LT),
      end_date: fnsFormatDate(arrival, FNS_DATE_FORMATS.BARS_DEFAULT),
      end_time: fnsFormatDate(arrival, FNS_DATE_FORMATS.LT),
      drop_off_time: manifest.drop_off_time,
      route: manifest.route,
      status: manifest.status,
      destinations: manifest.destinations
        ? manifest.destinations.map(destination =>
            this.mapInputDestination(destination)
          )
        : []
    };
  }
  public mapOutput(
    manifest: BatchTransfer.Manifest
  ): ExternalBatchTransfer.Output.ManifestDeclaration.Manifest {
    const departure =
      manifest.start_date && manifest.start_time
        ? fnsFormatDate(
            fnsParse(
              `${manifest.start_date} ${manifest.start_time}`,
              "MM/dd/yyyy hh:mm a"
            ),
            FNS_DATE_FORMATS.DEFAULT_WITH_TIME
          )
        : undefined;

    const arrival =
      manifest.end_date && manifest.end_time
        ? fnsFormatDate(
            fnsParse(
              `${manifest.end_date} ${manifest.end_time}`,
              "MM/dd/yyyy hh:mm a"
            ),
            FNS_DATE_FORMATS.DEFAULT_WITH_TIME
          )
        : undefined;

    return {
      drop_off_time: manifest.drop_off_time,
      route: manifest.route || "",
      driver_id: manifest.driver!.id!,
      vehicle_id: Number(manifest.vehicle!.id!),
      location_id: manifest.location_id,
      departure,
      arrival,
      destinations: manifest.destinations
        ? manifest.destinations.map(destination =>
            this.mapOutputDestination(destination)
          )
        : []
    };
  }

  protected mapInputDestination(
    destination: ExternalBatchTransfer.Input.ManifestDeclaration.Destination
  ): BatchTransfer.Destination {
    return {
      id: destination.id,
      order: destination.order,
      estimated_travel_time: destination.estimated_travel_time,
      estimated_arrival: destination.estimated_arrival,
      transfer_id: destination.transfer_id,
      route: destination.route,
      transfer: destination.transfer
        ? this.outboundMapper.mapInput(destination.transfer)
        : undefined,
      address: ""
    };
  }

  protected mapOutputDestination(
    destination: BatchTransfer.Destination
  ): ExternalBatchTransfer.Output.ManifestDeclaration.Destination {
    const arrival = destination.estimated_arrival
      ? fnsFormatDate(
          fnsParse(
            destination.estimated_arrival,
            FNS_DATE_FORMATS.EN_DASH_WITH_MERIDIEM_TIME
          ),
          FNS_DATE_FORMATS.DEFAULT_WITH_TIME
        )
      : undefined;

    const outputDestination: ExternalBatchTransfer.Output.ManifestDeclaration.Destination = {
      _destroy: !!destination._destroy,
      estimated_arrival: arrival,
      estimated_travel_time: destination.estimated_travel_time
        ? destination.estimated_travel_time
        : undefined,
      order: destination.order,
      transfer_id: destination.transfer_id,
      route: destination.route
    };

    if (destination.id) {
      outputDestination.id = destination.id;
    }

    return outputDestination;
  }
}
