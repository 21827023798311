import { policyList } from "@/enums/permissions";
import { BatchTransfer } from "@/interfaces/batchTransferManager";
import { Driver } from "@/interfaces/driver";
import { Location } from "@/interfaces/location";
import { Vehicle } from "@/interfaces/vehicle";
import { Vendor } from "@/interfaces/vendor";
import { EventBus } from "@/internal";
// tslint:disable-next-line
import manifestService from "@/services/BatchTransferManager/Manifest.service";
import outboundTransferService from "@/services/BatchTransferManager/OutboundTransfer.service";
import { driverService } from "@/services/driver.service";
import { messagesService } from "@/services/messages.service";
import { vehiclesService } from "@/services/vehicles.service";
import { PageNavAction } from "@/types/types";
import { FNS_DATE_FORMATS } from "@/utils/date-fns.utils";
import {} from "googlemaps";
import { BooleanCheck } from "helix-vue-components";
import { Component, Prop, Vue } from "vue-property-decorator";
import { Action, Getter } from "vuex-class";
import {
  calcRoute,
  parseAddress,
  parseLastStopArrival
} from "../routing.utils";
import Template from "./Manifest.template.vue";
import ManifestForm from "./ManifestForm/ManifestForm.component";
import OutboundTransferDestination from "./OutboundTransferDestination/OutboundTransferDestination.component";
import ManifestPrint from "./print/manifestPrint.component";
import RouteManifest from "./Route/Route.component";
import DirectionsLeg = google.maps.DirectionsLeg;

export interface AddressDefinition {
  address: string;
  address1: string;
  address2: string;
  city: string;
  state: string;
  zip: string;
}

export enum TimeUnitOfMesure {
  minutes = "minutes",
  hours = "hours"
}
export interface ManifestModel extends BatchTransfer.Manifest {
  destinations: BatchTransfer.Destination[];
  legs: google.maps.DirectionsLeg[];
  stepsRoutes: string[];
  timeUnitOfMesure: TimeUnitOfMesure;
  manualRoute: boolean;
}

export const emptyModel = {
  barcode: "",
  transfers: [],
  route: "",
  start_date: "",
  end_date: "",
  start_time: "",
  end_time: "",
  drop_off_time: "00:05:00",
  vehicle_id: -1,
  driver_id: -1,
  location_id: -1,
  legs: [],
  stepsRoutes: [],
  destinations: [],
  timeUnitOfMesure: TimeUnitOfMesure.minutes,
  manualRoute: false
};
@Component({
  mixins: [Template],
  components: {
    "outbound-transfer": OutboundTransferDestination,
    ManifestForm,
    RouteManifest
  }
})
export default class Manifest extends Vue {
  @Action("setPageNav", { namespace: "PageNavModule" })
  public setPageNav!: PageNavAction;
  @Prop() public transfers!: string;
  @Prop() public Manifest!: string;
  @Getter("hasPermission", { namespace: "PermissionsModule" })
  public hasPermission!: BooleanCheck;
  public model: ManifestModel = { ...emptyModel };
  public vehicles: Vehicle[] = [];
  public drivers: Driver[] = [];
  public loadingBtn = false;
  public loading: boolean = true;
  public editingPersisted: boolean = false;
  public manifestLoaded = false;
  public policyList = policyList;

  public async refreshDrivers() {
    this.drivers = await driverService.get({
      no_pagination: true,
      sort: "firstname"
    });
  }

  public async refreshVehicles() {
    this.vehicles = await vehiclesService.get({
      no_pagination: true,
      sort: "name"
    });
  }
  public async save(complete: boolean, print: boolean) {
    this.loadingBtn = true;
    try {
      if (!this.model.manualRoute) {
        this.model.destinations!.forEach(
          (destination, index) =>
            (destination.route = this.model.stepsRoutes[index])
        );
      }

      if (!this.editingPersisted) {
        const transfers = await this.saveTransfers();

        this.model.destinations!.map((destination, index) => {
          destination.transfer = transfers[index];
          destination.transfer_id = destination.transfer!.id!;
          return destination;
        });
      }
      const manifest = await this.saveManifest();
      const persisted = await manifestService.getById(manifest!.id!);

      if (complete) {
        await manifestService.complete(manifest!.id!);
      }
      if (print) {
        this.printManifest(persisted);
      }
      EventBus.$emit("BatchTransferListTouched", "manifest");
      this.$router.push({
        name: "batch-transfer",
        params: { type: "manifest" }
      });
    } catch (e) {
      messagesService.renderErrorMessage(e);
      this.loadingBtn = false;
    }
  }

  public async editTransfers() {
    this.$router.push({
      name: "transfer-manager",
      params: {
        type: "outbound-transfer",
        transfers: this.transfers
      }
    });
  }

  public async updatedPriorityArray(
    transfers: BatchTransfer.Destination[]
  ): Promise<void> {
    this.model = {
      ...this.model,
      destinations: [...transfers],
      legs: await this.calcRoute(transfers)
    };
  }

  public async calcRoute(
    transfers: BatchTransfer.Destination[]
  ): Promise<DirectionsLeg[]> {
    this.loading = true;
    return calcRoute(this.model.location!, transfers)
      .then(result => {
        return result.routes[0].legs;
      })
      .catch(e => {
        messagesService.showMessage(
          "fa fa-exclamation-triangle",
          this.$t(
            "batch_transfer_manager_module.messages.route_not_found"
          ).toString(),
          "warning"
        );
        this.model.manualRoute = true;
        EventBus.$emit("manualRouteChange");
        return [];
      })
      .finally(() => {
        setTimeout(() => {
          this.loading = false;
        }, 500);
      });
  }

  public back() {
    this.$router.back();
  }

  public get canSubmit() {
    return (
      this.model.driver &&
      this.model.vehicle &&
      this.model.start_date &&
      this.model.start_time &&
      ((this.model.end_date && this.model.end_time && !this.model.route) ||
        (this.model.route && !this.model.manualRoute)) &&
      this.model.drop_off_time
    );
  }
  public get haveTransfers() {
    return !!this.model.destinations!.length;
  }

  protected async mounted() {
    this.setPageNav({
      title: "batch_transfer_manager_module.titles.outbound_transfer",
      rightActions: {
        generalActions: () => [
          {
            icon: "fal fa-times",
            action: this.back,
            vuetifyProps: () => ({
              fab: true,
              small: true,
              disabled: this.loading
            })
          }
        ]
      }
    });
    this.registerEventsHandler();
    const isLoaded = await this.loadManifest();

    if (!isLoaded) {
      this.$router.push({
        name: "transfer-manager",
        params: { type: "outbound-transfer" }
      });
      return;
    }

    if (!this.model.manualRoute) {
      this.model.legs = await this.calcRoute(this.model.destinations!);
    }

    this.refreshDrivers();
    this.refreshVehicles();
  }

  protected registerEventsHandler(): void {
    EventBus.$on("manualRouteChange", () => {
      this.refreshOnManualRoute();
    });
  }

  protected isManualRoute(manifestRoute?: string | null): boolean {
    return !manifestRoute || manifestRoute === "manual_route";
  }

  protected parseExistingDestination(): BatchTransfer.Destination[] {
    return this.model
      .destinations!.sort((a, b) => a.order - b.order)
      .map(destination => {
        const transferDestination: Location | Vendor = destination.transfer!
          .destination!;
        return {
          id: destination.id,
          address: parseAddress(
            transferDestination.address1 || transferDestination.address2,
            transferDestination.city ? `${transferDestination.city}` : "",
            transferDestination.state ? `${transferDestination.state}` : "",
            transferDestination.zip ? `${transferDestination.zip}` : ""
          ),
          name: transferDestination.name,
          order: destination.order,
          estimated_travel_time: destination.estimated_travel_time,
          estimated_arrival: parseLastStopArrival(
            destination.estimated_arrival,
            FNS_DATE_FORMATS.EN_DASH_WITH_MERIDIEM_TIME
          ),
          route: destination.route,
          transfer_id: destination.transfer!.id!,
          transfer: destination.transfer!
        };
      });
  }

  protected destinationFromJsonTransfer(): BatchTransfer.Destination[] {
    return JSON.parse(this.transfers).map(
      (transfer: BatchTransfer.OutboundTransfer, index: number) => {
        const transferDestination: Location | Vendor = transfer.destination!;
        return {
          address: parseAddress(
            transferDestination.address1 || transferDestination.address2,
            transferDestination.city ? `${transferDestination.city}` : "",
            transferDestination.state ? `${transferDestination.state}` : "",
            transferDestination.zip ? `${transferDestination.zip}` : ""
          ),
          name: transferDestination.name,
          order: index,
          route: "",
          estimated_travel_time: "",
          estimated_arrival: "",
          transfer_id: transfer.id,
          transfer
        };
      }
    );
  }

  protected async loadManifest(): Promise<boolean> {
    try {
      if (this.$route.params.id) {
        const manifest = await manifestService.getById(
          Number(this.$route.params.id)
        );
        this.editingPersisted = true;
        this.model.manualRoute = this.isManualRoute(manifest.route);
        this.model = { ...this.model, ...manifest };
        this.model.location = this.currentLocation;
        this.model.location_id = this.currentLocation.id;
        this.model.destinations = this.parseExistingDestination();
      } else {
        this.editingPersisted = false;
        this.model.manualRoute = false;
        this.model.location = this.currentLocation;
        this.model.location_id = this.currentLocation.id;
        this.model.destinations = this.destinationFromJsonTransfer();
      }

      this.manifestLoaded = true;
      this.loading = false;
      return true;
    } catch (e) {
      messagesService.renderErrorMessage(e);
      return false;
    }
  }

  protected get currentLocation() {
    return this.$store.state.AuthModule.currentLocation;
  }

  protected get currentLocationAddress() {
    const destination: AddressDefinition = this.currentLocation;
    return parseAddress(
      destination.address1,
      destination.city,
      destination.state,
      destination.zip
    );
  }

  protected async saveTransfers() {
    return await outboundTransferService.createCollection(
      this.model
        .destinations!.sort(
          (a: BatchTransfer.Destination, b: BatchTransfer.Destination) =>
            a.order - b.order
        )
        .filter(destination => !!destination.transfer)
        .map(transfer => transfer.transfer!)
    );
  }
  protected async saveManifest() {
    if (this.editingPersisted) {
      return await manifestService.update(this.model);
    } else {
      return await manifestService.create(this.model);
    }
  }

  protected async printManifest(manifest: BatchTransfer.Manifest) {
    EventBus.$emit("print", {
      component: ManifestPrint,
      props: {
        manifest
      }
    });
  }

  protected async refreshOnManualRoute(): Promise<void> {
    let legs: DirectionsLeg[] = [];

    if (!this.model.manualRoute) {
      legs = await this.calcRoute(this.model.destinations!);
    }

    const newModel: ManifestModel = {
      ...this.model,
      route: "",
      end_date: "",
      end_time: "",
      legs,
      destinations: !!this.model.destinations
        ? this.model.destinations.map(destination => {
            return {
              ...destination,
              estimated_arrival: "",
              estimated_travel_time: "",
              route: ""
            };
          })
        : []
    };

    this.model = newModel;
  }
}
