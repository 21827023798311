import {
  BatchTransfer,
  TRANSFER_STATUS
} from "@/interfaces/batchTransferManager";
import { Component, Prop, Vue } from "vue-property-decorator";
import Template from "./transferPage.template.vue";

@Component({
  mixins: [Template]
})
export default class TransferPageComponent extends Vue {
  @Prop({ required: true }) public manifest!: BatchTransfer.Manifest;
  @Prop({ required: true }) public destination!: BatchTransfer.Destination;
  @Prop({ required: true }) public index!: number;

  public get footerProps() {
    return { manifest: this.manifest };
  }

  public isDestinationVoided(destination: BatchTransfer.Destination): boolean {
    return destination.transfer!.status === TRANSFER_STATUS.CANCELED;
  }
}
